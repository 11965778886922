const cookieConsent = "yyt_cookieConsent";
const cookieAlert = "yyt_cookieAlert";
const cookies = [cookieConsent, cookieAlert];

$(window).on("load", function () {
  cookies.forEach((cookie) => {
    const value = getCookie(cookie);
    if (cookie === cookieConsent) {
      var offcanvasNode = document.querySelector("#Offcanvas");
      var offCanvasBottomNode = document.querySelector("#offcanvasBottom");
      if (offCanvasBottomNode !== null) offCanvasBottomNode.remove();
      if (value === "true") {
        offcanvasNode.appendChild(offcanvas("hide"));
      } else {
        offcanvasNode.appendChild(offcanvas("show"));
      }
    } else if (cookie === cookieAlert) {
      if (value === "true") {
        $("#messageModal").modal("hide");
      } else {
        $("#messageModal").modal("show");
      }
    }
  });
});

document.addEventListener(
  "click",
  function (event) {
    if (!event.target.matches("#cookieConsent")) return;
    event.preventDefault();
    setCookie(cookieConsent, true, 7);
  },
  false
);

document.addEventListener(
  "click",
  function (event) {
    if (!event.target.matches("#cookieDecline")) return;
    event.preventDefault();
    setCookie(cookieConsent, false, 7);
  },
  false
);

document.addEventListener(
  "click",
  function (event) {
    if (!event.target.matches("#closeModal")) return;
    event.preventDefault();
    setCookie(cookieAlert, true, 1);
  },
  false
);

function setCookie(cname, cvalue, exdays) {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  let expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/;Secure";
}

function getCookie(cname) {
  let name = cname + "=";
  let ca = document.cookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

function lang(value) {
  switch (value) {
    case "de":
      return {
        header: "Wir verwenden Cookies",
        body: "Diese Internetseite verwendet Cookies, um eine verbesserte Benutzerfreundlichkeit zu schaffen. </br>Wir verwenden ausschließlich funktionsrelevante Cookies, wenn Sie zustimmen. </br>In unserer <a class='link-light' href='/de/datenschutz.html' >Datenschutzerklärung</<a> finden Sie weitere Informationen zu Cookies und deren Einsatz.",
        accept: "Aktzeptieren",
        decline: "Ablehnen",
        disclaimerLink: "/de/impressum.html",
        disclaimerText: "Impressum",
        privacyPolicyLink: "/de/datenschutz.html",
        privacyPolicyText: "Datenschutz",
      };
    case "en":
      return {
        header: "We use cookies",
        body: "This website uses cookies to create an improved user experience. </br>We only use functionally relevant cookies if you agree. </br>Please see our <a class='link-light' href='/en/privacy-policy.html'>privacy policy</a> for more information on cookies and their use.",
        accept: "Accept",
        decline: "Decline",
        disclaimerLink: "/en/disclaimer.html",
        disclaimerText: "Disclaimer",
        privacyPolicyLink: "/en/privacy-policy.html",
        privacyPolicyText: "Privacy Policy",
      };
    case "zh":
      return {
        header: "我们使用Cookies",
        body: "本网站使用cookies来创造更好的用户体验。我们只在您同意的情况下使用功能相关的cookies。请参阅我们的隐私政策，了解更多关于cookies及其使用的信息。",
        accept: "接受",
        decline: "下降",
        disclaimerLink: "/zh/免责声明.html",
        disclaimerText: "免责声明",
        privacyPolicyLink: "/zh/隐私政策.html",
        privacyPolicyText: "隐私政策",
      };
    default:
      return {
        header: "Wir verwenden Cookies",
        body: "Diese Internetseite verwendet Cookies, um eine verbesserte Benutzerfreundlichkeit zu schaffen. </br>Wir verwenden ausschließlich funktionsrelevante Cookies, wenn Sie zustimmen. </br>In unserer <a class='link-light' href='/de/datenschutz.html' >Datenschutzerklärung</<a> finden Sie weitere Informationen zu Cookies und deren Einsatz.",
        accept: "Aktzeptieren",
        decline: "Ablehnen",
        disclaimerLink: "/de/impressum.html",
        disclaimerText: "Impressum",
        privacyPolicyLink: "/de/datenschutz.html",
        privacyPolicyText: "Datenschutz",
      };
  }
}

function offcanvas(value) {
  const header3 = document.createElement("h3");
  header3.className = "offcanvas-title";
  header3.id = "offcanvasBottomLabel";
  header3.innerHTML = lang(document.documentElement.lang).header;

  const divHeader = document.createElement("div");
  divHeader.className = "offcanvas-header";
  divHeader.appendChild(header3);

  const divBody = document.createElement("div");
  divBody.className = "col-12 col-lg-8 col-xl-9 offcanvas-body small";
  divBody.innerHTML = lang(document.documentElement.lang).body;

  const divButtons = document.createElement("div");
  divButtons.className = "col-12 col-lg-4 col-xl-3";
  const buttonAccept = document.createElement("button");
  buttonAccept.id = "cookieConsent";
  buttonAccept.className = "btn btn--success btn--block mb-3";
  buttonAccept.innerHTML = lang(document.documentElement.lang).accept;
  buttonAccept.setAttribute("data-bs-dismiss", "offcanvas");
  divButtons.appendChild(buttonAccept);
  const buttonDecline = document.createElement("button");
  buttonDecline.id = "cookieDecline";
  buttonDecline.className = "btn btn--danger btn--block mb-3";
  buttonDecline.innerHTML = lang(document.documentElement.lang).decline;
  buttonDecline.setAttribute("data-bs-dismiss", "offcanvas");
  divButtons.appendChild(buttonDecline);

  const uList = document.createElement("ul");
  uList.className = "list-inline text-center mb-0";
  const listItem1 = document.createElement("li");
  listItem1.className = "list-inline-item small";
  const linkDisclaimer = document.createElement("a");
  linkDisclaimer.className = "link-light";
  linkDisclaimer.href = lang(document.documentElement.lang).disclaimerLink;
  linkDisclaimer.innerHTML = lang(document.documentElement.lang).disclaimerText;
  listItem1.appendChild(linkDisclaimer);
  uList.appendChild(listItem1);
  const listItem2 = document.createElement("li");
  listItem2.className = "list-inline-item small";
  listItem2.innerHTML = "|";
  uList.appendChild(listItem2);
  const listItem3 = document.createElement("li");
  listItem3.className = "list-inline-item small";
  const linkPrivacyPolicy = document.createElement("a");
  linkPrivacyPolicy.className = "link-light";
  linkPrivacyPolicy.href = lang(document.documentElement.lang).privacyPolicyLink;
  linkPrivacyPolicy.innerHTML = lang(document.documentElement.lang).privacyPolicyText;
  listItem3.appendChild(linkPrivacyPolicy);
  uList.appendChild(listItem3);

  const divRow = document.createElement("div");
  divRow.className = "row mx-1";

  divRow.appendChild(divHeader);
  divRow.appendChild(divBody);
  divRow.appendChild(divButtons);
  divRow.appendChild(uList);

  const divOffcanvas = document.createElement("div");
  divOffcanvas.className = "offcanvas offcanvas-bottom cookies " + value;
  divOffcanvas.id = "offcanvasBottom";
  divOffcanvas.setAttribute("tabindex", "-1");
  divOffcanvas.setAttribute("aria-labelledby", "offcanvasBottomLabel");
  divOffcanvas.appendChild(divRow);

  return divOffcanvas;
}
